.navigation-drawer {
    width: 250px;
    overflow-x: hidden;
}

.navigation-drawer.MuiDrawer-paper {
    z-index: 1;
}

.navigation-drawer-sub-items {
    margin-left: 15px;
}

.bottom-items {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

/*
  Device = Tablets or desktop with small browser window
*/
@media only screen and (max-width: 1024px) and (min-device-width: 768px) {
    .navigation-drawer {
        width: 220px;
    }
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .navigation-drawer {
        width: 210px;
        overflow: scroll;
    }
}

/*
 When the height is too small add scrollbar.
 */
@media (max-height: 600px) {
    .bottom-items{
        justify-content: initial;
    }
}
