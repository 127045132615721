.markdown-content {
    text-align: left;
}

.page-content-box {
    word-break: break-word;
    border: 1px solid #dadce0;
    text-align: left;
    max-width: 800px;
    border-radius: 8px;
    margin: 0 auto;
    padding: 10px 25px;
    max-height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
}

.page-content-box .MuiList-padding,
.app-content-wrapper .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
}

.checkboxes-container {
    width: 100%;
    max-width: 800px;
    display: inline-grid;
    word-break: break-word;
    padding: 10px 25px 0;
    text-align: left;
    margin: 10px auto;
}

.checkboxes-container .MuiFormControlLabel-root {
    margin-right: 0;
}

.registration-stepper-wrapper
    .privacy-policy-wrapper:not(.is-scrollable)
    .page-content-box
    .markdown-content,
.registration-stepper-wrapper
    .privacy-policy-wrapper:not(.is-scrollable)
    .register-stepper-container {
    position: relative;
    padding: 0;
}

.consent-information,
.term-of-use {
    width: 100%;
    max-width: 800px;
    display: inline-grid;
    word-break: break-word;
    padding: 10px 25px 0;
    text-align: center;
    margin: 0 auto;
}

.consent-agreement,
.recovery-text {
    width: 100%;
    max-width: 800px;
    display: inline-grid;
    word-break: break-word;
    padding: 10px 25px 0;
    text-align: left;
    margin: 0 auto;
}

.flex-direction {
    flex-direction: column;
}

/*
  Device = Tablets
*/
@media only screen and (max-width: 1024px) and (min-device-width: 768px) {
    .consent-agreement,
    .recovery-text {
        padding: 10px 40px 0;
    }
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .page-content-box {
        width: auto;
        margin: 10px 20px;
        padding: 5px 15px;
    }

    .checkboxes-container {
        width: auto;
        margin: 0 20px;
        padding: 15px 0 0 5px;
    }

    .consent-information,
    .term-of-use {
        width: auto;
        margin: 0 20px;
        padding: 0 0 0 5px;
    }
}
