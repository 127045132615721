.title-of-news {
    resize: none;
    text-align: left;
    width: 800px;
}

.content {
    resize: none;
    text-align: left;
    margin: 0 auto 0 auto;
    width: 800px;
    height: 300px;
}

.preview {
    word-break: break-word;
    border: ridge darkgrey;
    width: 800px;
    padding: 10px;
    text-align: left;
    margin: 10px auto 30px auto;
    min-height: 100px;
}

.content-preview {
    display: block;
}

.markdown {
    text-align: center;
}

.date-style {
    text-align: right;
    padding-right: 30px;
}
.margin-box {
    margin-bottom: 3px;
}
.scrollable-div {
    overflow-y: auto;
    max-height: 100vh;
    align-self: center;
    width: 100%;
}

.news-success-alert {
    text-align: left;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 720px) {
    .content {
        width: 300px;
    }

    .title-of-news {
        width: 300px;
    }

    .news-box {
        width: 250px;
    }

    .news-success-alert {
        width: 300px;
    }

    .preview {
        width: 300px;
    }

    .markdown {
        font-size: 13px;
    }
}
/*
  Device = Tablets or desktop with small browser window
*/
@media only screen and (max-width: 1024px) and (min-device-width: 768px) {
    .news-box {
        width: 550px;
    }

    .news-success-alert,
    .content,
    .title-of-news,
    .preview {
        width: 600px;
    }
}

/*
  Device = Mobiles
*/
@media screen and (min-width: 768px) {
    .content {
        display: flex;
    }
}
