.app-settings-container {
    display: grid;
}

.item-app-settings {
    padding: 20px;
    display: flex;
}

.language-dropdown .MuiButtonBase-root {
    width: 27px;
    height: 23px;
}

.language-label {
    margin: 3px 20px 0;
}

.language-option {
    display: flex;
    margin-top: 10px;
}

.enable-info-screens-button {
    width: fit-content;
}

.enable-info-screens-container .MuiButton-root {
    margin: 20px;
}

.app-settings-container .itemAppSettings .MuiButtonBase-root.MuiCheckbox-colorPrimary {
    min-width: 58px;
}
