.MuiSvgIcon-root.icon-type {
    width: 30px;
    height: 30px;
}

.avatar-wrapper {
    margin: 1.5%;
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .MuiAvatar-root.avatar-wrapper,
    .MuiFab-sizeSmall.fab-wrapper {
        width: 35px;
        height: 35px;
    }

    .MuiSvgIcon-root.icon-type {
        width: 25px;
        height: 25px;
    }
}
