.product-type-wrapper {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-type-buttons-wrapper {
    display: flex;
    justify-content: center;
    margin: 16px;
}

.product-type-button {
    margin-left: 5% !important;
    margin-right: 5% !important;
}

.product-type-button .MuiButtonBase-root {
    width: 100%;
    display: block;
    margin: 10px auto;
}

.product-text {
    padding: inherit;
    text-align: center;
}

.product-item {
    width: min-content;
    padding: 10px;
}

.pulse {
    box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
    animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}
@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgb(42 95 166);
        box-shadow: 0 0 0 0 rgb(42 95 166);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}
