.home-stepper .MuiStepContent-root {
    margin-top: 0;
    margin-left: 18px;
}

.home-stepper .MuiStepContent-root .MuiButtonBase-root {
    margin: 3px 16px;
}

.home-stepper .MuiAccordionSummary-root .MuiIconButton-root {
    padding: 0;
}

.home-stepper .MuiAccordionSummary-content.Mui-expanded {
    margin: 12px 0;
}

.home-stepper .MuiAccordionSummary-root,
.home-stepper .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
}

.home-stepper .MuiStepLabel-root {
    align-items: flex-start;
}

.MuiStepIcon-text {
    visibility: hidden;
}

.MuiPaper-root.MuiStepper-root.MuiStepper-vertical.home-stepper.MuiPaper-elevation0{
    border-radius: 4px;
}

.home-stepper-accordion.MuiAccordion-root {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.remove-bottom-margin {
    margin-block-end: 0;
}

.simple-stepper-icon .MuiStepContent-root .MuiTypography-root {
    margin-left: 16px;
}

.home-stepper .simple-stepper-icon .MuiStepLabel-iconContainer {
    margin-top: 5px;
}

.phases-info-container {
    padding: 0 24px 24px;
}

.phase-information{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.phase-information,
.end-of-study.MuiTypography-root{
    margin-top: 10px;
}

.phase-information.regular-visit-info{
    margin-top: 24px;
}

.phases-info-container .MuiDivider-root{
    margin: 0 40px;
}

.step-summary {
    display: block;
}

.visit{
    text-align: center;
}

.infection-visit-info .MuiButton-contained,
.regular-visit-info .MuiButton-contained {
    margin-right: 10px;
    min-width: 30%;
}

.date-parentheses{
    white-space: nowrap;
}

/*
  Device = Tablets or desktop with small browser window
*/
@media only screen and (max-width: 1024px) and (min-device-width: 768px) {
    .infection-visit-info .MuiButton-contained,
    .regular-visit-info .MuiButton-contained {
        min-width: 40%;
    }
}

/*
  Device = Mobiles
*/

@media screen and (max-width: 767px) {
    .infection-visit-info .MuiButton-contained,
    .regular-visit-info .MuiButton-contained {
        min-width: 50%;
    }
}
