.app-logo {
    display: block;
    margin: 5% auto;
    width: auto;
    max-width: 15%;
}
.circular-progress-container:not(.hide) + .app-logo{
    display: none;
}
.text-alert-login {
    cursor: pointer;
}

.button-erase-dialog.MuiButtonBase-root {
    margin-top: 10px;
}

.button-erase-dialog.MuiButtonBase-root {
    float: left;
}

.login-text-fields {
    margin: 10px 0;
}

.login-text-fields .MuiFormLabel-root{
    font-size: 14px;
}

.title-register {
    text-align: center;
    font-weight: 700;
    margin-top: 10px;
}

.ok-button {
    float: right;
    margin: 0 auto;
}

.login-content {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    flex-wrap: wrap;
}

.login-introduction-box .page-content-box {
    max-width: 500px;
    margin-top: 0;
}

.existing-user-space {
    text-align: center;
}

.login-button {
    margin: 0 10px;
}

.login-button .MuiButtonBase-root {
    width: 100%;
}

.login-text {
    margin: 10px 0;
}

.paper-login.MuiPaper-root {
    height: 100%;
}

.paper-login.MuiPaper-root {
    width: 300px;
    border-radius: 8px;
    padding: 20px 30px;
    text-align: left;
    margin: 0 5px;
}

.login-back-button.MuiIconButton-root {
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
}

.login-introduction-box,
.login-box {
    margin-top: 10px;
}

.login-no-invitation {
    margin-top: 25px;
}

.login-description-text {
    margin-top: 0;
}

.margin-bottom.MuiButtonBase-root {
    margin-bottom: 10px;
}

/*
  Device = Tablets or desktop with small browser window
*/
@media only screen and (max-width: 1024px) and (min-device-width: 768px) {
    .login-no-invitation {
        margin-top: 0;
    }

    .app-logo {
        max-width: 20%;
    }
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .app-logo {
        max-width: 20%;
    }

    .button-erase-dialog.MuiButtonBase-root {
        width: 100%;
        margin: 10px auto;
    }

    .login-content .page-content-box {
        width: auto;
        margin: 0 20px 0;
    }

    .paper-login.MuiPaper-root {
        width: auto;
        margin: 0 20px 25px;
        padding: 20px 15px;
    }

    .login-content {
        width: 300px;
        display: initial;
        margin: auto;
    }

    .login-page-wrapper,
    .login-no-invitation {
        margin-top: 0;
    }

    .app-logo {
        margin-top: 10px;
    }
}
