.autocomplete-questionnaire-page-content {
    box-shadow: 0 0 0 3px #ffffff !important;
    background-color: #ff9800 !important;
    margin-bottom: 24px !important;
    margin-top: 24px !important;
    border: 0 !important;
}
.autocomplete-questionnaire-text-color {
    color: white;
    font-weight: 600 !important;
}
.autocomplete-questionnaire-slider .MuiSlider-markLabel {
    font-weight: 500;
    font-size: 1vh;
}
.autocomplete-page-content-logs {
    word-break: break-word;
    border: 1px solid #dadce0;
    text-align: left;
    max-width: 800px;
    border-radius: 8px;
    margin: 0 auto;
    max-height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
}

.autocomplete-page-content-logs-style-colored {
    background: bisque;
}

.autocomplete-page-content-logs-style-uncolored {
    background: unset;
}
.autocomplete-questionnaire-logs-hide-btn {
    float: right;
}
.autocomplete-questionnaire-logs-container {
    height: 40vh;
}

.autocomplete-questionnaire-tooltip-container {
    display: flex;
    margin-bottom: 16px;
}

.autocomplete-questionnaire-icon {
    color: white;
    height: 30px !important;
    margin-right: 8px;
}
.autocomplete-questionnaire-slider {
    color: black !important;
}
.autocomplete-questionnaire-progress {
    background-color: black !important;
}
.autocomplete-questionnaire-generate-button {
    background-color: #fffbc9 !important;
    color: black !important;
    font-weight: 600 !important;
    margin-top: 16px !important;
}

.autocomplete-questionnaire-button {
    margin-left: 16px !important;
}
