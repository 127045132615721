/* GENERAL */
* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    margin: 0;
}

body {
    font-family: Arial, monospace;
}

footer {
    bottom: 0;
    position: absolute;
    width: 100%;
    padding: 10px 10px 2px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-left {
    bottom: 1px;
    position: absolute;
}

.footer-left .MuiIconButton-root {
    border-radius: 0;
    padding: 0;
    margin-left: 8px;
}

.select-item .MuiButtonBase-root {
    padding: 0;
}

.footer-left .MuiSvgIcon-root {
    width: 22px;
    height: 17px;
}

.footer-center {
    margin: 0 auto;
    /*
    Padding is required for centering the footer in the middle of the
    page without considering 'powered by refinio' element and flags.
     */
    padding-left: 135px;
}

.footer-center div {
    display: inline;
}

.footer-center div,
.footer-right {
    margin-right: 5px;
    cursor: pointer;
    text-decoration: underline;
}
.footer-right {
    align-items: flex-end;
}

.footer-privacy-page:hover {
    color: #193b66;
}

.footer-left .MuiIconButton-root:hover {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
        0 1px 10px 0 rgba(0, 0, 0, 0.12);
}

.footer-center,
.footer-right {
    font-weight: 700;
    padding-top: 20px;
}

:root,
.mobile-app-overlay {
    background: var(--app-main-bg-color, #fefefe);
}

#root.root-for-login {
    background: var(--login-main-bg-color, #fef5e8);
}

/**
 *  Font-sizes
 */

/* Header */
.headline {
    font-size: 27px;
}

.paper-font-size h2 {
    font-size: 21px;
}

.event-button .MuiSvgIcon-root {
    font-size: 20px;
}

.display-question.questionBody {
    font-size: 15px;
}

/* Sub-header */
.paper-font-size h3,
.navigation-drawer-items .MuiListItemText-root .MuiTypography-root,
.list-title {
    font-size: 17px;
}

/* Content */
.simple-stepper-title,
.home-stepper-title,
.home-stepper-description,
.journal-event-date,
.types-list,
.questionBody,
.exit-dialog-text .MuiTypography-body1,
.exit-dialog-title .MuiTypography-root,
.pop-up-checkbox .MuiTypography-body1,
.erase-content,
.diary-entry-description,
.title-of-news,
.title-preview,
.content,
.content-text,
.content-preview,
.feedback-text {
    font-size: 16px;
}

.MuiTypography-body1,
.simple-stepper-content,
.paper-font-size {
    font-size: 14px !important;
}
.home-stepper-sub-title {
    font-size: 13px !important;
}

.cov-questions-view-mode {
    font-size: 15px;
}

/* Information box & dates for different events */
.patient-info .MuiAlert-message,
.dialog-content,
.consent-agreement,
.recovery-text {
    font-size: 14px;
}

.term-of-use,
.consent-information {
    font-size: 12px;
}

/* Questionnaire answers */
.answersSize .MuiTypography-root.MuiFormControlLabel-label,
.footer-center,
.footer-right {
    font-size: 13px;
}

.footer-links {
    display: contents;
}

/* Buttons */
.MuiButton-root {
    font-size: 12px !important;
}

/**
 * Colors
 */
:root {
    --app-main-bg-color: #e3f2fd;
}

:root {
    --login-main-bg-color: #fef5e8;
}
/* Sets the color defined by Material ui for the text */
.app-left-container,
.app-menu-entry,
.simple-stepper-introduction,
.MuiSlider-markLabel,
.infection-visit-info .MuiButton-contained,
.regular-visit-info .MuiButton-contained {
    color: rgba(0, 0, 0, 0.87);
}

.MuiAvatar-colorDefault.Journal,
.MuiAvatar-colorDefault.News,
.MuiAvatar-colorDefault.Diary,
.registration-stepper,
.ok-button.MuiButtonBase-root,
.ok-button.MuiButtonBase-root:hover,
.menu-button .MuiAvatar-root {
    background: #2a5fa6;
}

.navigation-drawer.MuiDrawer-paper {
    background: #61abec;
}

.footer-center,
.footer-right,
.MuiIconButton-label,
.login-back-button .MuiAvatar-colorDefault,
.event-button.MuiButton-root,
.display-question {
    color: #2a5fa6;
}

.patient-info,
.eventsAlert,
.home-stepper,
.patient-info.MuiAlert-standardInfo,
.paper,
.info,
.MuiSnackbar-anchorOriginTopCenter,
.MuiSnackbar-anchorOriginBottomCenter,
.MuiAvatar-colorDefault.FileCopy,
.MuiAvatar-colorDefault.AccountArrowRight,
.MuiAvatar-colorDefault.AccountArrowLeft,
.MuiAvatar-colorDefault.Close,
.MuiAvatar-colorDefault.Refresh,
.coloredRow,
.answer-buttons .MuiButton-root,
.no-background-avatar.my-information .MuiAvatar-colorDefault {
    background-color: #ffffff;
}

.input,
.ok-button.MuiButtonBase-root,
.par,
.title-text,
.menu-option .MuiListItemText-root,
.hide-slider-bullet .MuiSlider-thumb,
.autocomplete-questionnaire-slider .MuiSlider-markLabel {
    color: #ffffff;
}

.MuiAvatar-colorDefault.Erase,
.MuiAvatar-colorDefault.Home {
    background: #ff0000;
}

.visit.past {
    color: #ff0000;
}

.MuiAvatar-colorDefault.PatientInformation,
.MuiAvatar-colorDefault.Feedback,
.MuiAvatar-colorDefault.Settings,
.MuiAvatar-colorDefault.FAQ,
.MuiAvatar-colorDefault.TermsAndConditions,
.MuiAvatar-colorDefault.ViewLicense,
.MuiAvatar-colorDefault.Impressum,
.MuiAvatar-colorDefault.PrivacyPolicy,
.MuiAvatar-colorDefault.LockReset,
.MuiAvatar-colorDefault.Backup,
.MuiAvatar-colorDefault.AppSettings,
.MuiAvatar-colorDefault.Tag,
.MuiAvatar-colorDefault.Devices,
.MuiAvatar-colorDefault.Information{
    background: #0da67a;
}

.no-background-avatar .MuiAvatar-colorDefault,
.refresh-countdown .MuiButton-root:hover {
    background-color: transparent;
}

.MuiAvatar-colorDefault.Info,
.registration-stepper.MuiPaper-root,
.login-back-button .MuiAvatar-colorDefault {
    background: transparent;
}

.open-connection .MuiAvatar-colorDefault {
    background: #90ee90;
}

.close-connection .MuiAvatar-colorDefault {
    background: #f08080;
}

.MuiAvatar-colorDefault.FileCopy,
.MuiAvatar-colorDefault.AccountArrowRight,
.MuiAvatar-colorDefault.AccountArrowLeft,
.MuiAvatar-colorDefault.Close,
.MuiAvatar-colorDefault.Refresh {
    color: #282c34;
}

.MuiAvatar-colorDefault.Import {
    background: cadetblue;
}

.note {
    color: darkorange;
}

.partner-devices-step-title {
    color: #07a0ff;
}

/*HOME SCREEN*/
.initial-phase-home-screen {
    color: #d3d3d3 !important;
    background: #d3d3d3 !important;
}

.phase1-color {
    color: #bbdefb !important;
    background: #bbdefb !important;
}

.phase2-color,
.regular-visit-info .MuiButton-contained {
    background: #61abec !important;
}

.phase2-color {
    color: #61abec !important;
}

.phase3-color,
.hide-slider-bullet .MuiSlider-markActive {
    background: #2a5fa6 !important;
}

.phase3-color,
.home-stepper-title {
    color: #2a5fa6 !important;
}

.phase3-active-color {
    color: #ffea00 !important;
}

.infection-visit-info .MuiButton-contained,
.phase3-active-color {
    background: #ffea00 !important;
}

.journal-button-type.MuiButton-contained:hover,
.journal-button-type.MuiButton-contained:focus,
.journal-button-type.MuiButton-contained:active,
.journal-button-type.MuiButton-contained.selectedButton {
    background-color: #cccccc;
}

.journal-button-type.MuiButton-contained {
    background-color: #f2f2f2;
}

.error {
    background-color: rgba(255, 0, 0, 0.16);
}

.greyColor {
    background-color: #80808036;
}

.add-to-home-screen-header .MuiAvatar-root.MuiAvatar-colorDefault {
    color: #959595;
}

/**
  * Borders
  */
.cell,
.cellDay,
.row {
    border-bottom: 1px solid lightgray;
}

.phase3-active-color.MuiStepIcon-root.MuiStepIcon-active {
    border: 1px solid rgba(0, 0, 0, 0.87);
}

.body {
    border-right: 1px solid lightgray;
}

.error-color .MuiPaper-root {
    background-color: rgb(253, 236, 234);
}

.warning-color .MuiPaper-root {
    background-color: rgb(255, 244, 229);
}

.success-color .MuiPaper-root {
    background-color: rgb(237, 247, 237);
}

.consent-information,
.term-of-use {
    color: rgba(0, 0, 0, 0.38);
}

/**
 * Common css style
 */

.headline {
    margin-left: 0;
    margin-top: 8px;
}

.page-container {
    width: 800px;
    margin: 10px auto 0;
}

.patient-information .page-content-box,
.privacy-policy-wrapper .page-content-box {
    display: flex;
    flex: 1;
}

.settings-policies,
.recovery-container,
.patient-information,
.questionnaire-container {
    overflow: hidden;
    height: calc(100vh - 47px); /* the entire viewport - the height of the footer */
    display: flex;
    flex-direction: column;
}

.diary-entry-paper-view-mode {
    overflow: hidden;
    max-height: calc(100vh - 47px); /* the entire viewport - the height of the footer */
    display: flex;
    flex-direction: column;
}

.diary-entry-paper-view-mode .page-content-box {
    width: 100%;
}

.journal-button-type .MuiSvgIcon-root,
.eventIcon .MuiSvgIcon-root {
    height: 36px;
    width: 36px;
}

.app-menu-entry {
    text-decoration: inherit;
}

.hide,
.hide.circular-progress-container,
.hide.page-container,
.hide.MuiFormControlLabel-root,
.hide.MuiAlert-root,
.hide.MuiButtonBase-root {
    display: none;
}

.not-visible.registration-stepper-wrapper {
    visibility: hidden;
    position: absolute;
}

/* Cancel button from the info boxes aligned to info sign */
.MuiAlert-root .MuiAlert-action {
    align-items: flex-start;
    margin-top: 0.5%;
}

/* Loading wheel */
.circular-progress {
    margin-top: 45vh;
}

.circular-progress-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Used for all information boxes, the margin is used for a more better ui experience */
.information_box {
    margin-top: 8px;
}

/* Pop-up text */
.pop-up-checkbox .MuiTypography-body1 {
    margin: 5px;
}

/* Style for the button from the DiaryEntry view and Questionnaire view */
.event-button.MuiButtonBase-root {
    min-width: 0;
    pointer-events: all;
}

/* Stepper design */
.simple-stepper-introduction {
    padding: 11px 11px 0;
}

.simple-stepper .simple-stepper-title {
    font-weight: 700;
    min-height: 36px;
    display: flex;
}

.simple-stepper .simple-stepper-title div {
    margin: auto 0;
}

.simple-stepper .MuiStepLabel-labelContainer {
    margin: auto 5px;
}

.simple-stepper-icon svg.MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
    border-radius: 50%;
}

.simple-stepper-content {
    border-left: 1px solid #bdbdbd;
    margin-top: 8px;
    margin-left: -31px;
    padding: 10px 8px 0 30px;
}

.simple-stepper-content.last-step {
    border-left: 1px solid transparent;
}

.simple-stepper-icon + .MuiStepConnector-vertical {
    margin-left: 18px;
}

.simple-stepper .MuiStepConnector-lineVertical {
    min-height: 10px;
}

.simple-stepper-content .app-menu-entry.MuiListItem-gutters {
    padding-left: 0;
    padding-right: 0;
}

.hide-arrow .MuiSelect-icon {
    display: none;
}

.flag-list .MuiInputBase-input,
.flag-list .MuiSelect-select.MuiSelect-select,
.navigation-drawer .MuiList-padding {
    padding: 0;
}

.flag-list .MuiButtonBase-root {
    margin: 0;
}

.dialog-error .MuiAlert-root {
    padding: 6px 6px;
}

.buttons-container {
    display: flex;
    justify-content: center;
}

.buttons-container {
    margin-top: 10px;
    margin-bottom: 5px;
}

.button.MuiButtonBase-root {
    height: 30px;
}

.button-margin-left.MuiButtonBase-root {
    margin-left: 10px;
}

.message-title.MuiAlertTitle-root,
.list-title {
    font-weight: bold;
}

.stick-message-top {
    position: sticky;
    top: 0;
    z-index: 20;
}

.nav-buttons {
    display: inline-flex;
}

/*
  Device = Tablets or desktop with small browser window
*/
@media (max-width: 1112px) and (min-width: 1025px) {
    .app-right-container .page-container {
        width: 750px;
    }
}

@media only screen and (max-width: 1024px) and (min-device-width: 768px) {
    .page-content,
    .page-container,
    footer {
        min-width: 400px;
    }

    .app-right-container .page-container,
    .app-left-container .page-container:not(.login-content),
    .page-content-box {
        width: auto;
        margin: 10px 40px 0;
    }

    .app-left-container .page-container:not(.login-content) {
        padding: 0 40px 0 70px;
    }

    .registration-stepper-wrapper .privacy-policy-wrapper {
        padding: 0 40px;
    }

    .app-left-container .login-content,
    .registration-stepper-wrapper .checkboxes-container {
        padding: 10px 40px 0;
    }
}

/*
 When the height is too small add scrollbar.
 */
@media only screen and (max-height: 600px) {
    footer {
        position: unset;
    }

    .registration-stepper-wrapper {
        height: 100%;
    }
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    :root {
        --footer: 71px;
    }

    footer {
        position: absolute;
    }
    /**
        Font-sizes
     */
    /* Header*/
    .headline {
        font-size: 17px;
    }

    .menu-button-header .headline {
        margin-left: 35px;
    }

    /* Sub-header*/
    .navigation-drawer-items .MuiListItemText-root .MuiTypography-root,
    .paper-font-size h3 {
        font-size: 15px;
    }

    /* Content */
    .home-stepper-title,
    .home-stepper-description,
    .journal-event-date,
    .questionBody,
    .exit-dialog-text .MuiTypography-body1,
    .exit-dialog-title .MuiTypography-root,
    .pop-up-checkbox .MuiTypography-body1,
    .erase-content,
    .diary-entry-description,
    .title-of-news,
    .title-preview,
    .content,
    .content-text,
    .content-preview,
    .simple-stepper-title,
    .paper-font-size h2 {
        font-size: 16px;
    }

    .simple-stepper-content,
    .feedback-text,
    .MuiTypography-body1,
    .paper-font-size,
    .dialog-content,
    .dialog-error .MuiAlert-root,
    .consent-agreement,
    .recovery-text {
        font-size: 13px !important;
    }

    .term-of-use,
    .consent-information {
        font-size: 11px !important;
    }
    /* Information box & dates for different events*/
    .patient-info .MuiAlert-message,
    .message-font-size .MuiAlert-message.button {
        font-size: 12px;
    }

    /* Questionnaire answers*/
    .answersSize .MuiTypography-root.MuiFormControlLabel-label,
    .footer-center,
    .footer-right {
        font-size: 11px;
    }

    /* Buttons*/
    .MuiButton-root {
        font-size: 10px !important;
    }

    /**
      The font-size 16px it's equivalent with 100% font size and it's the default font size for all the browsers.
      By changing the the font-size below 16px will trigger an automate zoom when user tries to fill an input.
    */
    input,
    select,
    textarea {
        font-size: 16px !important;
    }

    .eraseButton.MuiButtonBase-root {
        margin-bottom: 20px;
    }

    .app-right-container .mobile-app-overlay {
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 25;
    }

    .app-left-container .page-container {
        margin-top: 3px;
    }

    .page-container {
        width: auto;
        margin: 10px 20px 0 20px;
    }

    .page-container .page-content-box {
        margin: 0 0 10px;
    }

    .settings-policies,
    .recovery-container,
    .patient-information {
        height: calc(100vh - 42px); /* the entire viewport - the height of the footer */
    }

    /* Pop-up text */
    .exit-dialog-text .MuiTypography-body1,
    .exit-dialog-title .MuiTypography-root,
    .pop-up-checkbox .MuiTypography-body1 {
        margin: 10px;
    }

    .simple-stepper-introduction {
        padding: 11px 0 0;
    }

    .simple-stepper.MuiStepper-root {
        padding: 24px 0;
    }

    .feedback-container .feedback-content {
        margin-bottom: 35px;
    }

    .menu-button-header {
        display: inline-block;
        margin-top: 5px;
    }

    .menu-option .MuiListItemIcon-root {
        min-width: 45px;
    }

    .app-menu-entry .MuiListItem-gutters,
    .navigation-drawer .MuiListItem-gutters {
        padding-left: 5px;
    }

    .navigation-drawer .MuiListItem-gutters {
    }
}

/*
  Device = Mobiles in landscape mode
*/
@media (max-device-width: 812px) and (orientation: landscape) {
    .mobile-landscape-notification {
        background-color: #e3f2fd;
    }
}

@media (max-width: 767px), (max-height: 600px) {
    .headline {
        margin-top: 15px;
    }

    .footer-right {
        margin: 0 auto;
    }

    .footer-center,
    .footer-right {
        padding: 0;
    }

    .footer-links {
        display: grid;
        margin-left: auto;
        margin-right: auto;
        position: relative;
    }

    .footer-links.hide {
        display: none;
    }
}
