.types-list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 30px 0;
    flex-wrap: wrap;
}

.journal-button-type.MuiButton-contained {
    margin: 0 10px 10px;
    text-transform: none;
}

.journal-button-type .MuiButton-root {
    line-height: 1.25;
}

.journal-event-date.MuiButtonBase-root {
    pointer-events: none;
}

.journal-event-date .MuiTypography-root {
    font-weight: 600;
}

.journal-entry .MuiListItemText-root {
    flex: 0 1 auto;
}

.journal-entry .MuiListItemIcon-root {
    padding-right: 20px;
}

.journal-entry .eventTime {
    padding-right: 20px;
    min-width: 45px;
}

.eventsAlert .MuiAlertTitle-root {
    font-weight: 600;
}

.journal-entry .MuiListItemIcon-root {
    min-width: auto;
}

.journal-entry.MuiListItem-gutters {
    padding-right: 0;
    padding-left: 0;
}

.journal-event-date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .journal-button-type.MuiButton-contained {
        margin: 0 5px 7px !important;
        padding: 0 3px;
        width: 80px;
        height: 70px;
    }

    .journal-button-type.MuiButton-root {
        line-height: 1.25;
    }

    .eventTime .MuiTypography-body1 {
        width: 40px;
    }

    .journal-entry .MuiListItemIcon-root,
    .journal-entry .eventTime {
        padding-right: 5px;
    }

    .journal-entry.MuiListItemText-root {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}
