.types-list {
    padding: 0;
}

.qr-code {
    float: left;
    text-align: center;
    margin: 15px 25px 15px 0;
}

.qr-code-details {
    overflow: hidden;
    margin: 15px 0;
}

.qr-code-details .MuiListItemIcon-root,
.qr-code-details .avatar-wrapper.FileCopy {
    margin-left: auto;
    margin-right: auto;
}

tr {
    height: 50px;
}

th {
    max-width: 200px;
    min-width: 50px;
    padding: 0 !important;
    overflow-x: auto;
    white-space: nowrap;
}

td {
    height: 50px;
}

.connections-list.MuiPaper-root.MuiAccordion-rounded {
    margin-bottom: 10px;
    box-shadow: none;
}

.connections-list.MuiAccordionDetails-root {
    flex-direction: column;
}

.button-container {
    text-align: right;
    display: block;
}

.connections-list.MuiPaper-root.MuiAccordion-rounded {
    margin-bottom: 10px;
    box-shadow: none;
}

.connections-list .types-list {
    display: initial;
}

.no-background-avatar .MuiAvatar-colorDefault {
    height: 15px;
    width: 15px;
}

.no-background-avatar .MuiAvatar-fallback {
    display: none;
}

.my-info-summary {
    margin-left: -30px;
}

.refresh-countdown {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -10px;
    margin-right: -20px;
}

.counter {
    padding-top: 20px;
    margin-right: -12px;
}

@media screen and (max-width: 720px) {
    th {
        max-width: 90px;
    }

    .qr-code {
        float: none;
        margin: 15px 0 0;
    }

    .qr-code-details {
        margin: 0;
    }

    .refresh-countdown {
        margin-right: 0;
    }
}
