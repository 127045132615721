.text-box {
    margin: 15px 10px;
}

.diary-entry-view-mode {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.diary-entry-description {
    margin: 5px 0 10px;
}
