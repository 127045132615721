.questionnaire-container .menu-button-header{
    display: block;
}

.menu-button-header .headline{
    display: inline-block;
}

.questionnaire-progress-indicator{
    float: right;
    margin-top: 15px;
}

.cov-questions {
    align-self: center;
}

.cov-questions-view-mode {
    pointer-events: none;
    align-self: center;
    font-size: 15px;
}

.questionBody {
    display: flex;
    padding: 5px 10px 10px 10px;
}


.componentContainer.MuiList-root {
    border-top: 1px solid #8c95a2;
    padding: 0;
}

.answersBodyBasicQuestion {
    display: flex;
    padding-left: 20px;
    padding-bottom: 10px;
    flex-wrap: wrap;
}

.greyColor {
    pointer-events: none;
    opacity: 0.9;
}

.page-content-box.questionnaire-content {
    padding: 5px 0 0;
    flex: 1 1;
}

.answersSize .MuiTypography-root.MuiFormControlLabel-label {
    line-height: normal;
}

.textFieldAnswer div.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    width: 200px;
    height: 40px;
}

.string-question.MuiFormControl-root {
    margin-right: 20px;
}

.autocomplete .MuiAutocomplete-root {
    width: 200px;
    height: 40px;
}

.autocomplete-input .MuiAutocomplete-inputRoot {
    height: 40px;
    padding: 1px 0 0 0 !important;
}

.answersSize.MuiFormControlLabel-root {
    margin: 0;
}

.openChoiceWidth {
    width: 253px;
}

.gkm-info-container {
    margin-top: 8px;
    max-width: 800px;
}

.openChoiceContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.openChoice {
    width: 100%;
    padding-left: 20px;
}

.title-element {
    padding: 5px;
}

.view-buttons-container {
    pointer-events: all !important;
    text-align: center;
}

.choice {
    width: 253px;
    margin-top: 3px;
}

.radio-text-height {
    height: 38px;
}

.remove-top-border.MuiList-root {
    border-top: none;
}

.questionnaires-redirect-button {
    float: right;
    margin: 10px;
}

.answersBodyBasicQuestion .MuiSlider-root {
    margin-right: 40px;
    margin-left: 30px;
}

.slider .MuiSlider-markLabel {
    width: 80px;
    top: 20px;
    white-space: pre-wrap;
    text-align: center;
}

.hide-slider-bullet .MuiSlider-thumb {
    opacity: 0;
    pointer-events: none;
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    /**
    This prevent the overlapping between the answers of a question of Type Choice.
     */
    .choice {
        margin-top: 7px;
    }
}
