#root {
    min-height: 100%;
    position: relative;
}

.app-content-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.app-left-container,
.app-right-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 36px; /* the height of the footer */
}

.app-right-container {
    margin-left: 250px;
}

.app-right-container + footer {
    padding-left: 260px;
}

.app-menu {
    flex-shrink: 0;
    z-index: 30;
}

.mobile-landscape-notification,
.mobile-app-overlay {
    display: none;
}

.remove-double-tap-zoom {
    touch-action: manipulation;
}

/*
  Device = Tablets or desktop with small browser window
*/
@media only screen and (max-width: 1024px) and (min-device-width: 768px) {
    .app-right-container {
        margin-left: 210px;
    }

    .app-right-container + footer {
        padding-left: 220px;
    }
}

/*
 When the height is too small add scrollbar.
 */
@media only screen and (max-height: 600px) {
    #root {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: auto;
    }

    .app-left-container {
        padding-bottom: 0;
    }

    .app-left-container,
    .app-right-container {
        padding-bottom: 32px; /* the height of the footer */
    }
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .app-left-container,
    .app-right-container {
        padding-bottom: 32px; /* the height of the footer */
    }

    .app-right-container {
        margin-left: 0;
    }

    .app-right-container .mobile-app-overlay {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 25;
    }

    .app-right-container + footer {
        padding-left: 10px;
    }
}

/*
  Device = Mobiles in landscape mode
*/
@media (max-device-width: 812px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    .mobile-landscape-notification {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        z-index: 100;
    }

    .mobile-landscape-notification svg {
        height: 150px;
        width: 150px;
        align-self: center;
    }

    .mobile-landscape-notification div {
        align-self: center;
        margin-top: 15px;
    }
}
