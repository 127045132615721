.registration-stepper-wrapper {
    overflow: hidden;
    height: calc(100vh - 47px); /* the entire viewport - the height of the footer */
    display: flex;
    flex-direction: column;
}

.registration-stepper {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    flex-grow: 0;
}

.registration-stepper .MuiStepLabel-iconContainer {
    padding-right: 0;
}

.content-text {
    margin: 15px;
}

.box-margin {
    margin-bottom: 10px;
}

.button_right_margin.MuiButtonBase-root {
    margin-right: 1%;
}

.register-stepper-buttons-container,
.recovery-button {
    width: 100%;
    text-align: center;
    flex-grow: 0;
    padding: 20px 0;
}

.recovery-button .MuiButtonBase-root:first-child {
    margin-right: 10px;
}

/*
  Device = Tablets or desktop with small browser window
*/
@media only screen and (max-width: 1024px) and (min-device-width: 768px) {
    .registration-stepper {
        width: 75%;
    }
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .registration-stepper-wrapper {
        height: calc(100vh - 42px); /* the entire viewport - the height of the footer */
    }

    .registration-stepper {
        width: 100%;
    }
}
