.exit-dialog-buttons.MuiDialogActions-root {
    justify-content: space-between;
}

.exit-dialog-text-field.MuiFormControl-root {
    display: flex;
    padding: 16px 0;
}

.dialog-margin {
    margin: 0;
}
