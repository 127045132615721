.top-bar-dots {
    position: fixed;
    right: 3px;
    top: 0;
    cursor: pointer;
}

.menu-icon {
    position: fixed;
    top: 0;
    margin: 8px 0 0 11px;
}

.menu-icon .MuiFab-root {
    margin-left: 5px;
}

.button-menu {
    position: absolute;
    left: 0;
    top: 8px;
    margin-left: 16px;
}

.app-left-container .page-container .button-menu {
    margin-top: 0;
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .button-menu {
        margin-top: 0;
        margin-right: 10px;
        display: inline;
        float: left;
        top: inherit;
    }
}
