.connection-iom-close-alert {
    margin: 20px;
    max-width: 1100px;
}

.connection-iom-closed-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.connection-iom-closed-buttons .MuiButton-root {
    margin: 0 5px;
}

.password-text-field.MuiFormControl-root{
    min-width: 220px
}
