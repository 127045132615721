.info-button.MuiButtonBase-root {
    display: block;
    margin: 10px auto 20px;
}

.settings-policies .page-content-box {
    margin-bottom: 10px;
}

.center-component {
    display: flex;
    justify-content: center;
    align-items: center;
}

.center-component .MuiCircularProgress-root {
    margin-top: 30px;
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .info-button.MuiButtonBase-root {
        margin-bottom: 40px;
    }
}
