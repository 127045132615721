.privacy-policy-wrapper {
    width: 100%;
    text-align: center;
    display: flex;
    flex: 1;
}

.privacy-policy-wrapper .page-content-box {
    position: relative;
}

.registration-stepper-wrapper .privacy-policy-wrapper:not(.is-scrollable) {
    height: auto;
    flex: 0;
    min-height: fit-content;
}
.register-stepper-container .MuiStepLabel-root {
    align-items: flex-start!important;
}

.privacy-policy-wrapper .page-content-box .markdown-content,
.privacy-policy-wrapper .register-stepper-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: auto;
    bottom: 0;
    padding: 0 25px;
}

/*
 When the height is too small add scrollbar.
 */
@media only screen and (max-height: 600px) {
    .privacy-policy-wrapper {
        height: 350px;
    }
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .privacy-policy-wrapper {
        height: auto;
    }
}
