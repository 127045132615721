.pop-up-button.MuiButtonBase-root {
    margin: 4px;
}
.erase-pop-up-buttons.MuiDialogActions-root {
    justify-content: space-between;
}
.erase-pop-up-buttons {
    display: block;
}

.erase-action-buttons {
    display: block !important;
}

/*
  Device = Mobiles
*/
@media screen and (max-device-width: 767px) {
    .pop-up-button.MuiButtonBase-root {
        margin: 5px 0 0;
        width: 100%;
    }
}
